.thanks-container {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 0 2vw;
	width: 35vw;
	height: 40vw;
}

.thanks-container h3 {
	color: #ffffff;
	margin: 0;
	text-align: center;
	font-size: 2.5vw;
	margin: 15px 0;
}

@media (max-width: 950px) {
	.thanks-container {
		width: 65vw;
		height: 60vw;
	}

	.thanks-container h3 {
		font-size: 4.5vw;
	}
}

@media (max-width: 650px) {
	.thanks-container {
		width: 70vw;
		height: 85vw;
	}

	.thanks-container h3 {
		font-size: 5.5vw;
	}
}
