.planCard-container {
	width: 20vw;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	background-color: #ffffff;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.planCard-container:nth-of-type(1) {
	left: 5vw;
}

.planCard-container:nth-of-type(3) {
	right: 5vw;
}

.planCard-container h3 {
	font-size: 2vw;
	margin: 2vw 0;
	color: grey;
}

.planCard-container h1 {
	width: 100%;
	font-size: 3.5vw;
	padding: 2vw 0;
	margin: 0;
	background-color: rgb(210, 210, 210);
}

.planCard-container p {
	font-size: 1vw;
	padding: 1vw;
	margin: 0;
}

.planCard-container p:nth-of-type(1) {
	height: 6vw;
	display: flex;
	align-items: center;
}

.planCard-container > :nth-child(4) {
	width: 80%;
}

/* a {
	text-decoration: none;
	color: white;
} */

@media (max-width: 950px) {
	.planCard-container {
		width: 28vw;
		position: unset;
	}

	.planCard-container h3 {
		font-size: 3.5vw;
	}

	.planCard-container h1 {
		font-size: 4.5vw;
		padding: 30px 0;
	}
	.planCard-container p:nth-of-type(1) {
		font-size: 2vw;
		height: 16vw;
		min-height: fit-content;
	}

	.planCard-container > :nth-child(4) {
		margin: 2vw 0;
		width: 100%;
	}

	.planCard-container p:nth-of-type(2) {
		font-size: 2vw;
	}
}

@media (max-width: 650px) {
	.planCard-container {
		width: 75%;
		margin: 0 0 4vw 0;
	}

	.planCard-container h3 {
		font-size: 7vw;
	}
	.planCard-container h1 {
		font-size: 7vw;
	}

	.planCard-container p:nth-of-type(1) {
		font-size: 3.5vw;
		height: 24vw;
		display: flex;
		align-items: center;
		padding: 2vw;
	}

	.planCard-container p:nth-of-type(2) {
		font-size: 3.5vw;
		padding: 2vw;
	}
}
