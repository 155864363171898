.facts-section-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-areas:
		"title title title"
		"laptop smartphone calendar"
		"bottom-banner bottom-banner bottom-banner";
	padding: 2rem;
	background: url("../assets/images/facts-bottom-background.png") no-repeat;
	background-position: bottom;
	background-size: 100% 30%;;
}

.facts-section-container > :nth-child(1) {
	grid-area: title;
}

.facts-section-container > :nth-child(2) {
	grid-area: laptop;
}

.facts-section-container > :nth-child(3) {
	grid-area: smartphone;
}

.facts-section-container > :nth-child(4) {
	grid-area: calendar;
}

.facts-section-container h4 {
	font-size: 1.8vw;
	color: #2a0449;
	margin-bottom: 4vw;
	padding: 0 10vw;
}

.facts-section-container section {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.facts-section-container section div {
	background-color: #5c337a66;
	border-radius: 50%;
	width: 3.5vw;
	height: 3.5vw;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2vw;
}

.facts-section-container section:nth-of-type(1) img {
	height: 65%;
}

.facts-section-container section:nth-of-type(2) img,
.facts-section-container section:nth-of-type(3) img {
	height: 100%;
}

.facts-section-container section p {
	font-size: 1.5vw;
	font-weight: 700;
	padding: 1vw 5vw;
	color: #2a0449;
	height: 4vw;
	margin: 0;
}

.facts-section-container > :nth-child(5) {
	grid-area: bottom-banner;
	flex-direction: row;
	justify-content: space-evenly;
	padding: 2vw 0;
}

.facts-section-container > :nth-child(5) h3 {
	width: 40%;
	font-size: 2.8vw;
	color: #2a0449;
}

.facts-section-container > :nth-child(5) img {
	width: 25%;
}

@media (max-width: 950px) {
	.facts-section-container {
		padding: 2rem;
	}

	.facts-section-container h4 {
		font-size: 2.5vw;
		padding: 0;
	}

	.facts-section-container section div {
		width: 5vw;
		height: 5vw;
	}

	.facts-section-container section p {
		font-size: 2vw;
	}

	.facts-section-container > :nth-child(5) {
		padding: 4vw 0;
	}

	.facts-section-container > :nth-child(5) h3 {
		font-size: 3.2vw;
	}
}

@media (max-width: 650px) {
	.facts-section-container {
		grid-template-columns: repeat(1, 1fr);
		grid-template-areas:
			"title"
			"laptop"
			"smartphone"
			"calendar"
			"bottom-banner";
		background-size: 100% 8%;
	}

	.facts-section-container h4 {
		font-size: 6vw;
		margin: 2vw 0;
	}

	.facts-section-container section {
		flex-direction: row;
		justify-content: flex-start;
		margin: 2vw 0;
	}

	.facts-section-container section div {
		width: 10vw;
		height: 10vw;
		padding: 4vw;
	}

	.facts-section-container section p {
		font-size: 4.8vw;
		text-align: left;
		height: fit-content;
	}

	.facts-section-container > :nth-child(2),
	.facts-section-container > :nth-child(3),
	.facts-section-container > :nth-child(4) {
		padding: 0 2vw;
	}

	.facts-section-container > :nth-child(5) h3 {
		width: 90%;
		font-size: 5vw;
		padding: 0;
	}
}
