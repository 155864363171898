.subscribe-section {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-template-areas: "more text button";
	align-items: center;
	padding: 2vw 6vw;
	background-color: #5c337a;
	color: #fff;
	width: 100%;
	box-sizing: border-box;
}

.subscribe-section > :nth-child(1) {
	grid-area: more;
	width: 30vw;
	font-size: 2.5vw;
}

.subscribe-section > :nth-child(2) {
	grid-area: text;
	width: 40vw;
	font-size: 1.4vw;
}

.subscribe-section > :nth-child(3) {
	grid-area: button;
	width: 20vw;
}

@media (max-width: 950px) {
	.subscribe-section {
		grid-template-areas:
			"more more button"
			"text text button";
	}

	.subscribe-section > :nth-child(1) {
		font-size: 3.2vw;
		justify-self: center;
		width: fit-content;
	}

	.subscribe-section > :nth-child(2) {
		margin: 0;
		font-size: 2.2vw;
		justify-self: center;
		width: fit-content;
	}

	.subscribe-section > :nth-child(3) {
		justify-self: center;
		width: 35vw;
	}
}

@media (max-width: 650px) {
	.subscribe-section {
		grid-template-columns: 1fr;
		grid-template-areas:
			"more"
			"text"
			"button";
	}

	.subscribe-section > :nth-child(1) {
		font-size: 6vw;
		margin: 0;
	}

	.subscribe-section > :nth-child(2) {
		font-size: 3.7vw;
		margin: 4vw 0;
	}

	.subscribe-section > :nth-child(3) {
		width: 80vw;
	}
}
