.home-features > .home-features,
.home-facts,
.home-subscribe,
.home-howItWorks {
	max-width: 100%;
	display: flex;
	justify-content: center;
}


