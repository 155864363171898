.quotation-container{
    padding: 0 1vw;
    border-radius: 20px;
    background-color: #2a0449e2;
}

.quotation-container > img{
    position: absolute;
    top: 1vw;
    right: 1vw;
    width: 1.5vw;
    height: 1.5vw;
    cursor: pointer
}

@media (max-width: 950px) {
    .quotation-container{
    padding: 0 3vw;
}
 .quotation-container > img{
    top: 2vw;
    right: 2vw;
    width: 3vw;
    height: 3vw;
 }
	
}

@media (max-width: 650px) {
        .quotation-container{
    padding: 0 5vw;
}
 .quotation-container > img{
    top: 2.5vw;
    right: 2.5vw;
    width: 3.5vw;
    height: 3.5vw;
 }
}