.howItWorks-container {
	display: grid;
	background-color: #5c337a;
	color: white;
	grid-template-columns: repeat(2, 2fr);
	grid-template-areas:
		"title title"
		"first second"
		"third forth"
		"fifth sixth";
	padding: 2vw 14vw;
	gap: 2vw;
}

.howItWorks-container section:nth-of-type(1) {
	grid-area: title;
	text-align: center !important;
}

.howItWorks-container section:nth-of-type(2) {
	grid-area: first;
}

.howItWorks-container section:nth-of-type(3) {
	grid-area: second;
}

.howItWorks-container section:nth-of-type(4) {
	grid-area: third;
}

.howItWorks-container section:nth-of-type(5) {
	grid-area: forth;
}

.howItWorks-container section:nth-of-type(6) {
	grid-area: fifth;
}

.howItWorks-container section:nth-of-type(7) {
	grid-area: sixth;
}

.howItWorks-container section h1 {
	font-size: 2.8vw;
}

.howItWorks-container section:nth-of-type(odd) {
	text-align: left;
}

.howItWorks-container section:nth-of-type(even) {
	text-align: right;
}

.howItWorks-container section h4 {
	font-size: 2.5vw;
	margin: 1vw 0;
}

.howItWorks-container section p {
	font-size: 1.5vw;
	font-weight: 700;
}

.howItWorks-container section img {
	width: 100%;
}

.howItWorks-container section img {
	border-radius: 10px;
}

@media (max-width: 950px) {
	.howItWorks-container section h1 {
		font-size: 4vw;
	}

	.howItWorks-container section h4 {
		font-size: 3vw;
	}

	.howItWorks-container section p {
		font-size: 1.8vw;
	}
}

@media (max-width: 650px) {
	.howItWorks-container {
		grid-template-columns: repeat(1, 1fr);
		grid-template-areas:
			"title"
			"first"
			"second"
			"forth"
			"third"
			"fifth"
			"sixth";
		gap: 0;
		padding: 2rem;
	}

	.howItWorks-container section h1 {
		font-size: 8vw;
		margin: 3vw 0;
	}

	.howItWorks-container section h4 {
		font-size: 6vw;
		margin-top: 3vw;
		text-align: left;
	}

	.howItWorks-container section p {
		font-size: 4vw;
		margin: 2vw 0;
		text-align: left;
	}
}
