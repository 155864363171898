.aboutUs-container {
	/* background: url(../assets/images/oleksandr-pidvalnyi-2680937.jpg) 50% 0
		no-repeat;
	background-size: cover; */
	height: 500px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.aboutUs-container section {
	padding: 208px 288px 80px 288px;
}

.aboutUs-container section h5 {
	color: #828282;
	font-size: 1.2rem;
	text-align: left;
}
