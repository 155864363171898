.footer-container footer {
	position: absolute;
	bottom: 0;
	left: 0;
	height: 200px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	background-color: #2A0449;
}

.footer-container footer section {
	width: 40%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.footer-container footer section a {
	cursor: pointer;
}

.footer-container footer section img {
	width: 35px;
}

.footer-container footer p {
	color: #a7a7a7;
	font-size: 18px;
	margin: 0;
}
