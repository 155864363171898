.homeTitle-container {
	background: url(../assets/images/features-top-background.png) no-repeat;
	background-size: 110% 90%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10vw 0 2vw 0;
}

.homeTitle-textContainer {
	width: 40%;
	padding: 2rem;
	text-align: center;
}

.homeTitle-textContainer h1 {
	font-size: 3vw;
	color: #2a0449;
	margin-top: 0;
}

.homeTitle-textContainer p {
	font-size: 1.6vw;
	font-weight: 400;
	color: #2a0449;
}

.homeTitle-textContainer label {
	font-weight: 1000;
	font-size: 1.8vw;
}

.homeTitle-imgContainer {
	width: 35%;
}

.homeTitle-imgContainer img {
	max-width: 50%;
	height: auto;
	object-fit: cover;
}

@media (max-width: 950px) {
	.homeTitle-container {
		padding-top: 16vw;
		background-size: 111% 68%;
	}

	.homeTitle-textContainer h1 {
		font-size: 3.8vw;
	}

	.homeTitle-textContainer p {
		font-size: 3vw;
	}

	.homeTitle-textContainer label {
		font-size: 3.2vw;
	}
}

@media screen and (max-width: 650px) {
	.homeTitle-container {
		background-size: 170% 50%;
		justify-content: center;
	}

	.homeTitle-textContainer {
		margin: auto;
		width: 100%;
	}

	.homeTitle-textContainer h1 {
		font-size: 8vw;
		padding: 0 10vw;
	}

	.homeTitle-textContainer p {
		font-size: 7vw;
		padding: 0;
	}

	.homeTitle-textContainer label {
		font-weight: 700;
		font-size: 7.5vw;
	}

	.homeTitle-imgContainer {
		display: none;
	}
}

@media screen and (min-width: 1920px) {
	.homeTitle-container {
		background-size: 110vw;
	}

	.homeTitle-imgContainer img {
		max-width: 50%;
	}
}
