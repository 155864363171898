.plans-container {
	background: url(../assets/vectors/products.svg) no-repeat center bottom;
	background-size: 110vw;
	padding: 2vw;
}
.plans-container > h1 {
	font-size: 3.2vw;
	color: #2a0449;
	margin: 2vw 0;
}
.plans-container > p {
	font-size: 1.4vw;
	font-weight: 700;
	color: #2a0449;
	padding: 0vw 20vw;
	margin: 0vw 0vw 3vw;
}
.plans-container > section {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
}

@media (max-width: 950px) {
	.plans-container {
		background-size: 150vw;
		height: fit-content;
		padding: 2rem;
	}

	.plans-container > h1 {
		font-size: 4vw;
	}
	.plans-container > p {
		font-size: 2.5vw;
		color: #2a0449;
		padding: 1vw 3vw;
	}
}

@media (max-width: 650px) {
	.plans-container > section {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 2vw 0;
	}
	.plans-container {
		background-size: 100vw;
	}

	.plans-container > h1 {
		font-size: 6vw;
	}
	.plans-container > p {
		font-size: 4vw;
		color: #2a0449;
		padding: 1vw 3vw;
	}
}
