.Features-section {
	background: url(../assets/images/features-top-background.png) no-repeat;
	background-size: 100% 29%;
	display: flex;
	padding: 4vw 5vw 2vw 5vw;
	color: #2a0449;
}

.Features-title > h1 {
	font-size: 3.2vw;
	color: #2a0449;
}

.Features-section > .Features-left {
	width: 70%;
	display: flex;
	flex-direction: column;
	align-content: center;
	justify-content: center;
}

.Features-section > .Features-right {
	width: 30%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 5vw;
}

.Features-right img {
	width: 70%;
}

.Features-wrapper {
	display: flex;
	flex-wrap: wrap;
}

.Features-wrapper > .Feature-object {
	flex: 1 1 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.Feature-object > .Feature-icon-section {
	width: 35%;
}

.Feature-object > .Feature-text-section {
	width: 65%;
	text-align: start;
}

.Feature-object > .Feature-text-section h4 {
	font-size: 1.9vw;
	margin: 0;
}

.Feature-object > .Feature-text-section p {
	font-size: 1.5vw;
}

.circle-icon {
	background: #2a044954;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	text-align: center;
	line-height: 100px;
	vertical-align: middle;
	padding: 20px;
}

@media (max-width: 950px) {
	.Features-section {
		padding: 2rem;
		margin: 0;
		width: 100%;
		box-sizing: border-box;
		background-size: 100% 15%;
	}

	.Features-title > h1 {
		font-size: 6vw;
	}

	.Features-section > .Features-left {
		width: 100%;
	}

	.Features-right {
		display: none !important;
	}

	.Feature-object > .Feature-text-section h4 {
		font-size: 2.9vw;
		margin: 0;
	}

	.Feature-object > .Feature-text-section p {
		font-size: 2vw;
	}
}

@media (max-width: 650px) {
	.Features-section {
		background-size: 100% 10%;
	}

	.Features-wrapper {
		display: flex;
		flex-direction: column;
	}

	.Features-wrapper > .Feature-object {
		flex: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 2vw;
	}
	.Feature-object > .Feature-icon-section {
		flex: 1;
		margin-right: 1vw;
	}

	.Features-title > h1 {
		font-size: 7vw;
		text-align: center;
	}

	.Feature-object > .Feature-text-section {
		flex: 1 50%;
		text-align: start;
	}

	.Feature-object > .Feature-text-section h4 {
		font-size: 5vw;
	}

	.Feature-object > .Feature-text-section p {
		font-size: 3.5vw;
		margin: 2vw 0;
	}
}
